import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {
	@Output() ffp_delete = new EventEmitter();
	@Input() loader : boolean = true;
	@Input() membership : any;

  constructor() { }

  ngOnInit() {
  }
  close() {
  	this.ffp_delete.emit(0);
  }
  confirm() {
  	this.ffp_delete.emit(1);
  }

}
