import { Component, EventEmitter, Output, Input, ViewChild, OnInit,TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, MemberService } from '../../services';
import { MemberEnrollService } from "../../services/memberenroll.service";
// import { FlatpickrOptions } from 'ng2-flatpickr';
import * as moment from 'moment-timezone';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormsModule,
  NgForm
} from "@angular/forms";


@Component({
  selector: 'app-retro-claim',
  templateUrl: './retro-claim.component.html',
  styleUrls: ['./retro-claim.component.less']
})
export class RetroClaimComponent implements OnInit {
  @ViewChild('modalloader')
  private modaloader: TemplateRef<any>;
  @ViewChild('modalretroclaimpoints')
  private modalclaimpoints: TemplateRef<any>;
  @ViewChild('startPicker') pickerStart;
	@Input() memberData: any;
	@Input() tabSelected: any;
	@Input() sponsor_details: any;
  @Output() virtualReload = new EventEmitter();

  modalRef: any;
  memberships: any;
	FFPDetails;
	profileId: string = "";
	pms: string = "";
	resort: string = "";
	reservation_id: string = "";
	loader: boolean = false;
  invalidInput: boolean = false;
	tier_point_balance:any;
	tier_point_for_calculation:any;
  form: FormGroup;
  tab: string = "retro-claim";
  externalId: string = '';
  hotelCode: string = '';
  startDate: string = '';
  endDate: string = '';

  constructor(	private modalService: NgbModal,
    private commonService: CommonService,
    private memberService: MemberService,) { }
  showPopup(modalid, modalclass) {
		this.open(modalid, modalclass)
	}
  open(content, modalclass) {
		this.modalRef = this.modalService.open(content, { windowClass: modalclass });
	}
  ngOnInit() {
    this.profileId = this.commonService.getParam('g').toString();
    this.resort = this.commonService.getParam('p').toString();
    this.reservation_id = this.commonService.getParam('r').toString();
    this.hotelCode = this.resort;
	}
  close() {
		this.modalService.dismissAll();
	}

  retroClaimData: any;
  retroClaim() {
    var req = {
      external_id: this.externalId,
      hotel_code: this.hotelCode,
      h_start_date: moment(this.startDate[0]).format("YYYY-MM-DD"),
      h_end_date: moment(this.endDate[0]).format("YYYY-MM-DD"),
    }
    
		this.memberService
			.retroClaim(req)
			.then((data: any) => {
        if(data.length==0){
          this.invalidInput=true;
        }
        this.retroClaimData = data;
        setTimeout(() => {
          this.open(this.modalclaimpoints,'modalretroclaimpoints');
        }, 500);
			})
			.catch(error => {
			});
  }

}
