import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numbersonly]'
})
export class NumbersonlyDirective {

  constructor(private _el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this._el.nativeElement.value;
        const remzero = initalValue.replace(/^0+/, '');
        this._el.nativeElement.value = remzero.replace(/[^0-9]*/g, '');
        if (remzero !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

}
