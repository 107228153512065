import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from "./../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import * as cloneDeep from 'lodash/cloneDeep';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    backClick: boolean = false;

    constructor(private http: HttpClient,
    private translate: TranslateService) { }
    private host = environment.host();
    private gravtyhost = environment.gravtyhost();
    scrollTop() {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 5);
    }

    showCommonErrorMsg(error) {
        let errorMsg = '';
        if (error && error.error) {
            error = error.error;
            if (error && error.message) {
                errorMsg = error.message;
            } else if (error[0] && error[0].message) {
                errorMsg = error[0].message;
            } else if (error.error && error.error.error && error.error.error[0] && error.error.error[0].message) {
                errorMsg = error.error.error[0].message;
            } else if (error.error && error.error.message) {
                errorMsg = error.error.message;
            } else if (error.error) {
                errorMsg = error.error;
            } else {
                errorMsg = this.translate.instant('COMMON_ERROR');
            }
        } else {
            errorMsg = this.translate.instant('COMMON_ERROR');
        }
        return errorMsg;
    }

    setBackClicked(backclick) {
        this.backClick = backclick;
    }

    getBackClicked() {
        return this.backClick;

    }
    showErrorMsg(error, errorFlag, errorMsg) {
        errorFlag = true;
        if (Object.keys.length > 0) {
            this.scrollTop();
            if (error && error.email !== undefined) {
                errorMsg = this.translate.instant(error.email.code);
            } else if (error && error.name !== undefined) {
                errorMsg = error.name[0].message;
            } else if (error &&  error.message) {
                errorMsg = error.message;
            } else if (error && error.non_field_errors) {
                errorMsg = error.non_field_errors[0].message;
            } else if (error && error.error && error.error.error && error.error.error[0].message) {
                errorMsg = error.error.error[0].message;
            } else if (error && error.error) {
                errorMsg = error.error;
            } else if (error && error.error && error.error.message) {
                errorMsg = error.error.message;
            } else {
                let Messages = Object.keys(error).map((key) => (error[key][0].message));
                errorMsg = Messages.toString();
            }

        }
    }

    getParam(name){
        const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if(!results){
          return 0;
        }
        return results[1] || 0;
    }

    getData(datatype, value) {
        let url;
        switch (datatype) {
            case 'lookups': {
                url = this.gravtyhost + "lookups/?type=" + value + "&active=True";
                break;
            }
            case 'state': {
                url = this.gravtyhost + "regions/" + value + "/";
                break;
            }
            case 'city': {
                url = this.gravtyhost + "cities/" + value + "/";
                break;
            }
            case 'country': {
                url = this.gravtyhost + "countries/";
                break;
            }
            default: {
                break;
            }
        }
        return this.http.get(url).map(res => {
            return res;
        });
    }
}