import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { EnrolmentComponent } from './enrolment/enrolment.component';
import { ReportsComponent } from './reports/reports.component';

const routes: Routes = [{ path: '', component: ProfileComponent },
{ path: 'enrolment', component: EnrolmentComponent },
{ path: 'reports', component: ReportsComponent },
// otherwise redirect to home
{ path: '**', redirectTo: '' }];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
