import { Injectable } from "@angular/core";
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";

@Injectable({
  providedIn: "root"
})
export class MemberEnrollService {
  constructor(private webService: WebService) {}
    private host = environment.host();
    private gravityhost = environment.gravtyhost();
  checkMemberExist(request) {
    let requestUrl = this.host + "members/exists/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  memberEnrollment(request) {
    var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    if(request.membership_stage === 'FFP Member'){
      let requestUrl = this.host + 'program/'+ tenantInfo.id + '/gravty-member/' + request.member_id + '/';
      return this.webService.patchAPI(requestUrl, request, true);
    }
    else{
      let requestUrl = this.host + "program/"+ tenantInfo.id + "/gravty-member";
      return this.webService.postAPI(requestUrl, request, true);
    }
    
  }

  PmsIdEnrollment(request) {
    let requestUrl = this.host + "DynamoDBPostTransaction/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  getCountires = function() {
    var requestUrl = this.gravityhost + 'countries/';
  return this.webService.getAPI(requestUrl);
  };
  getSponsorDetails(id) {
    let requestUrl = this.gravityhost + "sponsors/"+id+'/';
    return this.webService.getAPI(requestUrl, false);
  }
  getSponsorDetailsByShortName(name) {
    let requestUrl = this.gravityhost + "sponsors/?short_name="+name+'&status=A';
    return this.webService.getAPI(requestUrl, false);
  }
  getStatesList = function(countryId) {
    var requestUrl = this.gravityhost + 'regions/' + countryId + '/';
    return this.webService.getAPI(requestUrl);
  };
  getStatesListWithPageNo = function(countryId, pageno) {
    var requestUrl = this.gravityhost + 'regions/' + countryId + '/?page=' + pageno;
    return this.webService.getAPI(requestUrl);
  };
  getCitiesList = function(statesId) {
    var requestUrl = this.gravityhost + 'cities/' + statesId + '/';
    return this.webService.getAPI(requestUrl);
  };

  getSummaryViewEnrolmentData(start_date,end_date,dimension,sponsorId,membershipStages,colleague_id,enrollmentCode?,sponsorname?) {
    let requestUrl;
    if(enrollmentCode){
      requestUrl = this.gravityhost + "member-enrollment/?start_date=" + start_date + "&end_date=" + end_date + "&dimension=" + dimension + "&sponsor_id=" + sponsorId + "&membership_stages=" + membershipStages + "&dimension_2=" +colleague_id + "&dimension_1=" + enrollmentCode;
    }else if(sponsorname){
      requestUrl = this.gravityhost + "member-enrollment/?start_date=" + start_date + "&end_date=" + end_date + "&dimension=" + dimension + "&sponsor_id=" + sponsorId + "&membership_stages=" + membershipStages + "&dimension_2=" +colleague_id + "&enrollment_code="+sponsorname;
    }else{
      requestUrl = this.gravityhost + "member-enrollment/?start_date=" + start_date + "&end_date=" + end_date + "&dimension=" + dimension + "&sponsor_id=" + sponsorId + "&membership_stages=" + membershipStages + "&dimension_2=" +colleague_id;
    }
   
    return this.webService.getAPI(requestUrl, false);
  }

  getEnrolledColleagueData(start_date,end_date,dimension,member_type,sponsorId,membershipStages,sponsorname?) {
    let requestUrl;
    if(sponsorname){
      requestUrl = this.gravityhost + "member-enrollment/?start_date=" + start_date + "&end_date=" + end_date + "&dimension=" + dimension + "&dimension_1=" + member_type + "&sponsor_id=" + sponsorId + "&membership_stages=" + membershipStages+"&enrollment_code="+sponsorname;
    }else{
      requestUrl = this.gravityhost + "member-enrollment/?start_date=" + start_date + "&end_date=" + end_date + "&dimension=" + dimension + "&dimension_1=" + member_type + "&sponsor_id=" + sponsorId + "&membership_stages=" + membershipStages;
    }
    
    return this.webService.getAPI(requestUrl, false);
  }

  getMemberData(start_date,end_date,colleague_id,totalData,sponsorId,membershipStage) {
    let requestUrl = this.gravityhost + "members/list/?date_of_joining_after=" + start_date + "&date_of_joining_before=" + end_date + "&colleague_id=" + colleague_id + "&page_size=" + totalData + "&sponsor_id=" + sponsorId  + "&membership_stage_in=" + membershipStage;
    return this.webService.getAPIWithResponseHeaders(requestUrl,false);
  }

  loadNextMemberData(start_date,end_date,colleague_id,totalData,sponsorId,membershipStage,cursor) {
    let requestUrl = this.gravityhost + "members/list/?date_of_joining_after=" + start_date + "&date_of_joining_before=" + end_date + "&colleague_id=" + colleague_id + "&page_size=" + totalData + "&sponsor_id=" + sponsorId  + "&membership_stage_in=" + membershipStage + "&cursor=" + cursor;
    return this.webService.getAPIWithResponseHeaders(requestUrl,false);
  }
}