import { Component, OnInit,Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-submit-retro-claim-points',
  templateUrl: './submit-retro-claim-points.component.html',
  styleUrls: ['./submit-retro-claim-points.component.less']
})
export class SubmitRetroClaimPointsComponent implements OnInit {

  @Input() retroClaimPointsData: any;
  @Input() record_id: any;
  objectString: string;
  splitString: string[] = [];
  errorMsg:string[]=[];
  constructor(private modalService: NgbModal,) { }

  ngOnInit() {
    this.objectString = JSON.stringify(this.retroClaimPointsData);
    this.splitString = this.objectString.split('","message":"');
    this.errorMsg=this.splitString[1].split('"');
  }
  close() {
		this.modalService.dismissAll();
	}

}
