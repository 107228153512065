import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppComponent } from "../app.component";
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, MemberService } from '../../services';
import { MemberEnrollService } from "../../services/memberenroll.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { Member, member } from "../../models";
import * as _ from "lodash";
import {timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators'; 

import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {
	@ViewChild('modalloader')
    private modaloader: TemplateRef<any>;
    @ViewChild('modalpaywithpoints')
    private modalpaywithpoints: TemplateRef<any>;
	frequentFlyers: any = [];
	selectFrequentFlyer: boolean = false;
	memberShipInJumeirahOne: boolean = true;
	// hideElement: boolean = true;
    public memberData: any;
   	error: boolean = false;
	errorMsg: string = "";
	lookups: any = {};
	blue_percent = 0;
	silver_percent = 0;
	gold_percent = 0;
	memberships: any;
	FFPDetails;
	modalRef: any;
	profileId: string = "";
	pms: string = "";
	resort: string = "";
	reservation_id: string = "";
	loader: boolean = false;
	tier_point_balance:any = 0;
	tier_point_for_calculation:any = 5000;
	ffp: any;
	countries: any;
	queryParams : any ;
	sponsor_details: any = {
	    name: "",
	    id: null,
	    address: ''
	};
	pmsAgentName: string = '';
	lines: string = '';
	tokenLoader: boolean = false;
	enrolment_code: string = '';
	view_report: string = '';
	sponsorID = null;
	pointsIndex: number;
	tierPointsIndex: number;
	addresslines: number = 1;
	tabSelected: number = 0;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private memberService: MemberService,
		private commonService: CommonService,
		private memberEnrollService: MemberEnrollService,
	) { }

	ngOnInit() {
		if(window.outerWidth<1440){
			var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
          	x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
		}
		this.route.queryParams.subscribe(queryParams => {
			if(this.queryParams==undefined){
				this.queryParams = queryParams;
			}
			else if(this.queryParams!= queryParams){
				location.reload();
			}
		});
		var flag=0;
		scrollTo(0,0);
		this.profileId = this.commonService.getParam('g').toString();
		this.resort = this.commonService.getParam('p').toString();
		this.enrolment_code = this.resort;
		this.view_report = this.resort;
		this.reservation_id = this.commonService.getParam('r').toString();
		this.pmsAgentName = this.commonService.getParam('u').toString().replace("%20", " ");
		if(this.pmsAgentName === '0'){
			this.pmsAgentName = '';
		}
		if(this.resort==='0' || (this.reservation_id==='0' && this.profileId === '0')){
			return;
		}
		localStorage.setItem('profileId',this.profileId) ;
		localStorage.setItem('resort',this.resort) ;
		localStorage.setItem('reservation_id',this.reservation_id) ;
		localStorage.setItem('sponsorID',this.sponsor_details.id);
		let subscription = timer(0, 1000).pipe().subscribe(x=>{
			this.myFunction();
			if(!this.tokenLoader){
				this.open(this.modaloader,'modalloader');
				this.tokenLoader = true;
			}
			if(flag==0){
				this.close();
				this.memberService.getFrequentFlyer().then((data: any) => {
					this.lookups["ffp"] = data.data;
				});
				this.commonService.getData("lookups", "TIER_CLASS").subscribe(result => {
					this.lookups["TIER_CLASS"] = result;
				});
				this.commonService.getData("lookups", "HOBBIES").subscribe(data => {
					this.lookups["HOBBIES"] = data;
				});
				// this.commonService.getData("lookups", "ffp").subscribe(result => {
				// 	this.lookups["ffp"] = result;
				// });
				if(this.profileId === '0' && this.reservation_id !== '0'){
					this.getInvoiceDetails();
				}
				else{
					this.getMemberData();
					this.sponsorDetails();
				}
				flag=1;
				subscription.unsubscribe();
			}
		})
	}

	myFunction() {
		if(window.outerWidth<1440){
			var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
          	x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
		}
		else{
			var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
          	x[0].style.zoom = '100%';
		}
	}

	sponsorDetails() {
		this.memberEnrollService
        .getSponsorDetailsByShortName(this.resort)
        .then((data: any) => {
            for(var j=0;j<data.length;j++){
                if(data[j].short_name === this.resort){
                    this.sponsor_details.name = data[j].name;
            		this.sponsor_details.address = data[j].address;
            		this.sponsor_details.id = data[j].id;
          		}
        	}
        });
	}

	addFrequentFlyerMembership() {
		this.selectFrequentFlyer = true;
	}

	cancelFrequentFlyerMembership() {
		this.getMemberData();
		this.selectFrequentFlyer = false;
	}

	filter_FFP(frequentFlyerName) {
		var list = ["Emirates Skywards", "JAL Mileage Bank", "Saudia Alfursan", "Aeroflot Bonus Program"];
		if(frequentFlyerName && list.includes(frequentFlyerName)){
			return true;
		}
		return false;
	}

	enrolmentCodeError = false;
	gotoEnrolmentPage() {
		if(this.profileId != '0' && this.resort != '0'){
			localStorage.setItem('pmsData',JSON.stringify(this.memberData));
			localStorage.setItem('enrolment_code',this.enrolment_code);
			if(this.memberData.udf_values && this.memberData.udf_values['J1EnrolmentSBU(MJ)']){
				localStorage.setItem('enrolment_code',this.memberData.udf_values['J1EnrolmentSBU(MJ)']);
			}
			// delete this.memberData.udf_values;
		}
		var url= window.location.href;
		var path = url.split('/#/');
		if(this.resort == 'MJ' && this.memberData.udf_values['J1EnrolmentSBU(MJ)'] == null) {
			this.enrolmentCodeError = true;
		} else {
			window.location.href= path[0] + '/#/enrolment?g=' + this.profileId + '&p=' + this.resort + '&u=' + this.pmsAgentName;
		}
	}

	goToTierbBenefits(){
		// window.location.href = 'https://www.jumeirah.com/en/loyalty/tier-benefits';
		window.open('https://www.jumeirah.com/en/loyalty/tier-benefits', '_blank');
	}

	open(content, modalclass) {
		this.modalRef = this.modalService.open(content, { windowClass: modalclass });
	}

	close() {
		this.modalRef.close();

	}
	showPopup(modalid, modalclass) {
		this.open(modalid, modalclass)
	}

	getInvoiceDetails() {
		this.loader = true;
		this.open(this.modaloader,'modalloader');
		this.memberService
			.getInvoiceDetails(this.reservation_id,this.resort)
			.then((data: any) => {
				this.close();
				data.reservation_id = this.reservation_id;
				localStorage.setItem('invoiceDetails',JSON.stringify(data));
				this.profileId = data.pms_profile_id;
				this.getMemberData();
				this.sponsorDetails();
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
				this.close();
			});
	}

	addMembership() {
		var object = [];
		for (var i = 0; i < this.memberships.length; i++) {
			var x = this.lookups["ffp"][i].value;
			if (this.memberships[i].selected == true && (this.memberships[i].value != this.lookups["ffp"][i].value || this.memberships[i].membershipName != this.lookups["ffp"][i].membershipName)) {
				if (this.lookups["ffp"][i].value == "" && this.lookups["ffp"][i].membershipName=="") {
					let req = { "ffp_program_name": this.memberships[i].name, 'name':this.memberships[i].membershipName,"ffp_membership_no": this.memberships[i].value, "member_id": this.memberData.member_id, "action":"CREATE" };
					object.push(req);
				}
				else {
					var id;
					for (var j = 0; j < this.frequentFlyers.length; j++) {
						if (this.frequentFlyers[j].ffp_program_name == this.lookups["ffp"][i].name) {
							id = this.frequentFlyers[j].id;
						}
					}
					let req = { "ffp_program_name": this.memberships[i].name,'name':this.memberships[i].membershipName, "ffp_membership_no": this.memberships[i].value, "member_id": this.memberData.member_id, "id": id, "action":"UPDATE" };
					object.push(req);
				}
			}
		}
		if(object.length>0){
			this.loader= true;
			this.open(this.modaloader,'modalloader');
			this.memberService.ffp(object).then((data: any) => {
				this.close();
				this.cancelFrequentFlyerMembership();
			})
			.catch(error => {
				this.close();
				if(!error.error)
					return;
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
		}
		else {
			this.selectFrequentFlyer = false;
		}
	}
	formatAddress(){
		this.addresslines = 1;
		if(this.memberData.country_code){
			this.countryMapping();
		}
		this.memberData.address = '';
		if(this.memberData.address_line1){
			this.memberData.address += this.memberData.address_line1 ;
		}
		if(this.memberData.address_line2){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += ', '
			}
			this.memberData.address += this.memberData.address_line2 ;
		}
		this.lines = this.memberData.address;
		if(this.memberData.city_text){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += '<br/>'
				this.addresslines += 1;
			}
			this.memberData.address += this.memberData.city_text ;
		}
		if(this.memberData.region_name){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += ', '
			}
			this.memberData.address += this.memberData.region_name ;
		}
		if(this.memberData.country_name){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += '<br/>'
				this.addresslines += 1;
			}
			this.memberData.address += this.memberData.country_name ;
		}
		document.getElementById('address').innerHTML = this.memberData.address;
		document.getElementById("preferences").style.marginTop = (12 - (3-this.addresslines)*22) + "px";
	}
	formatPreferences(){
		var preferences = "";
		if (this.memberData.hobbies) {
			for (var i = 0; i < this.memberData.hobbies.length; i++) {
				let index = _.findIndex(this.lookups["HOBBIES"], {
					"code": this.memberData.hobbies[i]
				});
				if(index !== -1 && preferences.length===0){
					preferences += this.lookups["HOBBIES"][index].name;
				}
				else if(index !== -1){
					preferences += ', ' + this.lookups["HOBBIES"][index].name;
				}
			}
		}
		this.memberData.member_pref = preferences;
	}
	countryMapping() {
	    if(this.memberData && this.memberData.country_code){
	        for(var i=0;i<this.countries.length;i++){
	            if(this.countries[i].iso_code === this.memberData.country_code){
	            	this.memberData.country_name = this.countries[i].name;
	        	}
	        }
	    }
	}
	getpmsData(profileId, resortId) {
		this.memberShipInJumeirahOne = false;
		this.loader = true;
		this.open(this.modaloader,'modalloader');
		this.memberService
			.getPmsProfile(profileId, resortId)
			.then((data: any) => {
				this.memberData = data;
				this.memberData.city_text = data.city_name;
				this.memberData.region_name = data.state_prov;
				if(data.ffp_details){
					this.frequentFlyers = data.ffp_details.data;
				}
				this.formatAddress();
				this.close();
			})
			.catch(error => {
				this.close();
				this.showErrorMsg(error);
			});
	}
	formatMemberData(data){
		this.FFPDetails = data["ffp_details"];
		this.frequentFlyers = [];
		this.memberData = data.data;
		localStorage.setItem('member_id',this.memberData.member_id);
		this.memberData.city_text =  data.data.extra_data.city_text;
		this.memberData.region_name =  data.data.extra_data.region_text;
		this.formatAddress();
		this.formatPreferences();
		var balance = this.memberData.balances;
		if(balance!==undefined && balance.length>0){
			for (var x = 0; x < balance.length; x++) {
				if (balance[x].loyalty_account == "Points") {
					localStorage.setItem('pointsIndex',x.toString());
					this.pointsIndex = x;
				}
			}
			if(this.pointsIndex) {
				this.memberData.balances[this.pointsIndex].balance = parseInt(this.memberData.balances[this.pointsIndex].balance.toFixed(0));
				this.memberData.balances[this.pointsIndex].balance_currency_amount = parseInt(this.memberData.balances[this.pointsIndex].balance_currency_amount.toFixed(0));
			}
			for (var i = 0; i < balance.length; i++) {
				if (balance[i].loyalty_account == "Tier Points") {
					balance[i].balance = parseInt(balance[i].balance.toFixed(0));
					this.tier_point_balance = balance[i].balance;
					this.tierPointsIndex = i;
				}
			}
			if(this.tier_point_balance==undefined){
				this.tier_point_balance = 0;
			}
			if(this.memberData && this.memberData.tier_class === 'JM'){
				this.tier_point_for_calculation=5000-this.tier_point_balance;
			}
			else if(this.memberData && this.memberData.tier_class === 'JS'){
				this.tier_point_for_calculation=30000-this.tier_point_balance;
			}
			else if(this.memberData && this.memberData.tier_class === 'JG'){
				this.tier_point_for_calculation=90000-this.tier_point_balance;
			} else if(this.memberData && this.memberData.tier_class === 'JP'){
				this.tier_point_for_calculation=90000-this.tier_point_balance;
			}
			if(this.tier_point_for_calculation%2===1){
				this.tier_point_for_calculation=this.tier_point_for_calculation+1;
			}
	    }

		if (this.memberData.tier_class === 'JM') {
			this.silver_percent = 0;
			this.gold_percent = 0;
			this.blue_percent = (this.tier_point_balance * 100) / 5000;
			if(this.blue_percent>=100){
				this.blue_percent = 102;
			}
		}
		else if (this.memberData.tier_class === 'JS') {
			this.gold_percent = 0;
			this.blue_percent = 102;
			this.silver_percent = ((this.tier_point_balance) * 100) / 30000;
			this.silver_percent = parseFloat(this.silver_percent.toFixed(2));
			if(this.silver_percent>=100){
				this.silver_percent = 102;
			}
		}
		else if (this.memberData.tier_class === 'JG') {
			this.silver_percent = 102;
			this.blue_percent = 102;
			this.gold_percent = ((this.tier_point_balance) * 100) / 90000;
			this.gold_percent = parseFloat(this.gold_percent.toFixed(2));
			if(this.gold_percent>=100){
				this.gold_percent = 102;
			}
		}
		else if(this.memberData.tier_class === 'JP'){
			this.silver_percent = 102;
			this.blue_percent = 102;
			this.gold_percent = 102;
		}
		// document.getElementById("blue").style.width = this.blue_percent + "%";
		// document.getElementById("silver").style.width = this.silver_percent + "%";
		// document.getElementById("gold").style.width = this.gold_percent + "%";
		let index = _.findIndex(this.lookups["TIER_CLASS"], {
			"code": this.memberData.tier_class
		});
		if (index !== -1) {
			this.memberData.tier_class_display = this.lookups["TIER_CLASS"][index].name;
		}

		if (data.ffp_details.data === undefined) {
			this.frequentFlyers = [];
			this.frequentFlyers.push(data.ffp_details);
		} else {
			this.frequentFlyers = data.ffp_details.data;
			for (var i = 0; i < this.lookups["ffp"].length; i++) {
				this.lookups["ffp"][i]['selected'] = false;
				this.lookups["ffp"][i]['value'] = "";
				this.lookups["ffp"][i]['membershipName'] = "";
				for (var j = 0; j < this.frequentFlyers.length; j++) {
					if (this.lookups["ffp"][i].name === this.frequentFlyers[j].ffp_program_name) {
						this.lookups["ffp"][i]['selected'] = true;
						this.lookups["ffp"][i]['value'] = this.frequentFlyers[j].ffp_membership_no;
						this.lookups["ffp"][i]['membershipName'] = this.frequentFlyers[j].name;
						this.lookups["ffp"][i].id = this.frequentFlyers[j].id;
					}

				}

			}
			let copy = JSON.parse(JSON.stringify(this.lookups["ffp"]))
			this.memberships = copy;
		}
	}

	getMemberData() {
		this.memberEnrollService
      		.getCountires()
            .then((data: any) => {
        		if(this.memberData){
           		this.close();
        	}      
        	this.countries = data;
        	this.countries.sort((a, b) => (a.name > b.name) ? 1 : -1);
        	this.countryMapping();
      	});
		this.loader = true;
		this.open(this.modaloader,'modalloader');
		this.memberService
			.getMemberData(this.profileId, this.resort)
			.then((data: any) => {
				this.close();
				this.formatMemberData(data);
			})
			.catch(error => {
				this.close();
				if(error === 'API not found'){
					this.getpmsData(this.profileId, this.resort);
					return;
				}
				if(!error.error)
					return;
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
	}
	delete_ffp(x) {
		this.loader = true;
		this.close();
		if(x==1){
			var obj = [];
			let req = {"id": this.ffp.id, "action": "DELETE" };
			obj.push(req);
			this.loader = true;
			this.open(this.modaloader,'modalloader');
			this.memberService.ffp(obj).then((data: any) => {
				this.ffp.selected = false;
				this.loader = false;
				this.close();
				this.getMemberData();
			})
			.catch(error => {
				this.close();
				this.showErrorMsg(error);
			});
		}
	}
	setFrequentFlyer(ffp) {
		this.ffp =ffp;
		if (ffp.selected) {
			for(var i=0;i<this.lookups['ffp'].length;i++){
				if(this.ffp.name==this.lookups['ffp'][i].name){
					if(!this.lookups['ffp'][i]['selected']){
						ffp.selected= false;
					}
					else{
						this.loader=false;
						return;
						this.open(this.modaloader,'modalDeleteConfirmation');
					}
				}
		}
		}
		else{
			ffp.selected = true;
		}
	}
	showErrorMsg(error) {
		this.error = true;
		this.commonService.scrollTop();
		this.errorMsg = this.commonService.showCommonErrorMsg(error);
	}
	resendInvitation(email,modalinvitationconfirmation){
		let _self = this;
		let request = {"email":email};
		this.loader = true;
		this.open(this.modaloader,'modalloader');
		this.memberService
			.resendInvitation(request)
			.then((data: any) => {
				this.close();
				this.showPopup(modalinvitationconfirmation,'modalinvitationconfirmation');
				setTimeout(function(){ _self.close(); }, 4000);
			})
			.catch(error => {
				this.close();
				this.showErrorMsg(error);
			});
	}

	gotoReportsPage(){
		if(this.profileId != '0' && this.resort != '0'){
			localStorage.setItem('pmsData',JSON.stringify(this.memberData));
			localStorage.setItem('view_report',this.view_report);
			localStorage.setItem('sponsorID',this.sponsor_details.id);
			if(this.memberData.udf_values && this.memberData.udf_values['J1ReportSBU(MJ)']){
				localStorage.setItem('view_report',this.memberData.udf_values['J1ReportSBU(MJ)']);
			}
			delete this.memberData.udf_values;
		}
		var url= window.location.href;
		var path = url.split('/#/');
		window.location.href= path[0] + '/#/reports?g=' + this.profileId + '&p=' + this.resort + '&u=' + this.pmsAgentName;
	}
}
