import { Component, OnInit, Input, Output, ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { CommonService, MemberService } from '../../services';
import * as moment from 'moment-timezone';
import * as _ from "lodash";
import {timer} from 'rxjs';
import { FlatpickrOptions } from 'ng2-flatpickr';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormsModule,
  NgForm
} from "@angular/forms";
@Component({
	selector: 'app-paywithpoints',
	templateUrl: './paywithpoints.component.html',
	styleUrls: ['./paywithpoints.component.less']
})
export class PaywithpointsComponent implements OnInit {
	@ViewChild('modalloader')
    private modaloader: TemplateRef<any>;
    @ViewChild('startPicker') pickerStart;
	@Input() memberData: any;
	@Input() tabSelected: any;
	@Input() sponsor_details: any;
	@Output() payWithPointsClose = new EventEmitter();
	@Output() virtualReload = new EventEmitter();
	tab: string = "paywithpoints";
	error: boolean = false;
	errorMsg: string = "";
	invoiceDetails: any = [];
	totalPoints: number = 0;
	totalBal: number = 0;
	totalBalance: number = 0;
	totalPts: number =0;
	remainingBal: number = 0;
	remainingPts: number = 0;
	confirmation; redemption;
	consume: boolean = false;
	awardOffers: any = [];
	searchval: string = "";
	offerData: any;
	loader: boolean = false;
	privileges: any = [];
	privsearchval: string = "";
	pointsConversion: any = [];
	conversion_rate: any;
	currency: any;
	modalRef: any;
	paymentData: any = {
		'data': []
	};
	filteredPrivileges: any = [];
	filteredAwardOffers: any = [];
	profileId: string;
	resort: string;
	reservation_id: string;
	confirmation_number: string;
	allSelected: boolean = true;
	pointsvalueInvoiceCurrency: any = 0;
	invoices:boolean = false;
	invoicesError:boolean = false;
	reservationProfileCheck: boolean = true;
	arrivalDate: string = '';
	departureDate: string = '';
	roomNumber: string = '';
	positiveBills: number = 0;
	selectedBills: number = 0;
	locations: any = [];
	dueError_count: number = 0;
	due_error_msg: string = 'Amount is greater than Due Amount.';
	pointsIndex: number = 0;
	AccRedempTypes: string = "ACTIVATION_EMAIL_RESEND ADJ BONUS_ACCRUAL_PARTNER BONUS_POINTS_INCENTIVE CARD_REPLACEMENT DINING EVENT FFPMANUAL FFPUPDATE FFP_MEMBERSHIP_TO_JO JUM_GIFT J_CLUB_POINTS_ACCRUAL MEMBEREXT MIGRATION NONSTAY NON_STAY_REVERSAL PARTIAL_ACC_REV PARTIAL_RDM_REV PARTNER PARTNER_ACCRUAL PARTNER_TIER_CHANGE PAYMENT_WITH_POINTS PENALTY POINTCONV POINTS_REINSTATE POINTS_REINSTATE_TP PRIVILEGE_TRANSFER SPEND STAY STAY_MANUAL_ADJUSTMENT THIRD_PARTY_REWARD THIRD_PARTY_TRANSFER TRNSFR ECERTIFICATE_REDEMPTION MIGRATION REDEMPTION";
	form: FormGroup;
	startOptions: FlatpickrOptions = {
	    dateFormat: 'd M Y',
	    defaultDate: null,
	    mode: "range",
	    maxDate: new Date(Date.now()),
	};
	loayltyAccounts: any = [];
	bitData: any = [];
	BitTypesList: any = [];
	BitCategoriesList: any = [];
	SponsorsList: any = [];
	bitcatgeory: string = "";
	bittype: string = "";
	selected_sponsor_id: string = "";
	datechange: boolean = false;
	prevUrl: string = "None";
	nextUrl: string = "None";
	todayData = new Date();
	constructor(
		private modalService: NgbModal,
		private memberService: MemberService,
		private commonService: CommonService,
		private formBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.form = this.formBuilder.group({
	      start: null,
	    });
		this.profileId = this.commonService.getParam('g').toString();
		this.resort = this.commonService.getParam('p').toString();
		this.reservation_id = this.commonService.getParam('r').toString();
		if(this.memberData.balances.length>0){
			this.pointsIndex = parseInt(localStorage.getItem('pointsIndex'));
		}
		this.getInvoiceDetails(1);
		this.getBitCategoriesList();
		this.getSponsorsList();
		this.getLoyaltyAccounts();
		this.commonService.getData("lookups", "BIT_TYPE").subscribe((data: any[]) => {
			for(var i=0;i<data.length;i++){
				var accred_types = this.AccRedempTypes.split(' ');
				if(accred_types.indexOf(data[i].code) !== -1){
					this.BitTypesList.push(data[i]);
				}
			}
		});
		if(this.tabSelected === 3){
			let subscription = timer(0, 100).pipe().subscribe(x=>{
				this.tab='bitdetails';
				this.bindChange();
				this.getBitData(false);
				subscription.unsubscribe();
			})
		}
	}

	showPopup(modalid, modalclass) {
		this.open(modalid, modalclass)
	}

	closePopup(x) {
		this.modalRef.close();
		if(x==2){
			return;
		}
		if(x==1){
			this.tab = "consumerewards";
			this.getPrivileges();
		}
		if(x==0){
			this.open(this.modaloader,'modalloader');
			this.memberService
				.getMemberData(this.profileId, this.resort)
				.then((data: any) => {
					this.modalRef.close();
					this.memberData = data.data;
					if(this.memberData.balances.length>0){
						this.pointsIndex = parseInt(localStorage.getItem('pointsIndex'));
					}
					this.virtualReload.emit(data);
					if(this.tab==='paywithpoints'){
						this.getInvoiceDetails(0);
					}
					if(this.tab==='redeemrewards'){
						if(this.memberData.balances.length>0 && this.invoices ){
							this.pointsvalueInvoiceCurrency = parseFloat(((this.memberData.balances[this.pointsIndex].balance) * (this.conversion_rate)).toFixed(2));
						}
						this.getAwardOffers();
					}
				});
		}
	}

	close() {
		this.payWithPointsClose.emit(0);
	}

	getBitCategoriesList(){
        this.memberService
        	.getBitCategoriesList()
            .then((data: any) => {
            	this.BitCategoriesList = [];
            	for(var i=0;i<data.length;i++){
            		if(data[i].code === 'ACCRUAL' || data[i].code === 'REDEMPTION'){
            			this.BitCategoriesList.push(data[i]);
            		}
            	}
            });
    }

    getSponsorsList(){
    	this.memberService
        	.getSponsorsList()
            .then((data: any) => {
                this.SponsorsList = data;
            });
    }

	omit_char(event)
	{   
	   var k;  
	   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
	   return( k == 46 || (k >= 48 && k <= 57)); 
	}

	BalanceUpdate(invoice,flag) {
		var x;
		if(flag==0){
			x = (<HTMLInputElement>document.getElementById('window_'+invoice.window_number)).value
		}
		else{
			x = invoice.inputAmount;
		}

		if(flag===1 && invoice.selected && invoice.dueError){
			this.dueError_count++;
		}
		if(flag===1 && !invoice.selected && invoice.dueError){
			this.dueError_count--;
		}
		if(flag===3 && !invoice.inputAmount || invoice.inputAmount[0]==='-'){
			invoice.inputAmount = 0;
			x = '0';
		}
		if(flag === 0 && x===''){
			x='0';
		}
		var amount = parseFloat(x);

		if(!invoice.dueError && amount > invoice.current_balance){
			invoice.dueError = true;
			this.dueError_count++;
		}
		if(invoice.dueError){
			if(amount <= invoice.current_balance){
				invoice.dueError = false;
				this.dueError_count--;
			}
		}
		var pts = parseFloat((parseFloat(x)/this.conversion_rate).toString());
		invoice.Points = parseInt((parseFloat(x)/this.conversion_rate).toString());
		if(pts-invoice.Points >= 0.5){
			invoice.Points += 1;
		}
		this.totalBal = 0;
		this.totalPoints = 0;
		for(var i=0;i<this.invoiceDetails.length;i++){
			if(this.invoiceDetails[i].selected){
				if(invoice.window_number == this.invoiceDetails[i].window_number){
					this.totalBal += parseFloat(x);
				}
				else{
					this.totalBal += parseFloat(this.invoiceDetails[i].inputAmount);
				}
				this.totalPoints += this.invoiceDetails[i].Points;
			}
		}
	}

	getInvoiceDetails(x) {
		if(x==0){
			localStorage.removeItem('invoiceDetails');
		}
		this.loader = true;
		this.invoicesError = false;
		var invoicedata;
		if(localStorage.getItem('invoiceDetails') != undefined){
			invoicedata = JSON.parse(localStorage.getItem('invoiceDetails'));
		}
		this.memberService
			.getPointsConversionList()
			.then((data: any) => {
				
				this.pointsConversion = data;
				if(this.memberData.balances.length>0){
					this.currency = this.memberData.balances[this.pointsIndex].balance_currency;
				}
				else{
					this.currency = 'AED';
				}
				if(invoicedata != undefined && invoicedata.reservation_id === this.reservation_id){
					this.loader = false;
					this.profileId = invoicedata.pms_profile_id
					this.processInvoiceDetails(invoicedata);
				}
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
		if(!(invoicedata != undefined && invoicedata.reservation_id === this.reservation_id)){
			if(this.reservation_id==='0'){
				this.invoices = false;
				this.invoicesError = true;
				this.loader = false;
				return;
			}
			this.memberService
				.getInvoiceDetails(this.reservation_id,this.resort)
				.then((data: any) => {
					this.loader = false;
					data.reservation_id = this.reservation_id;
					localStorage.setItem('invoiceDetails',JSON.stringify(data));
					this.profileId = data.pms_profile_id
					this.processInvoiceDetails(data);
					
				})
				.catch(error => {
					this.invoicesError = true;
					this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
					this.loader = false;
				});
		}
	}

	processInvoiceDetails(data) {
		this.allSelected = true;
		if(this.profileId != data.pms_profile_id){
			this.reservationProfileCheck = false;
			return;
		}
		this.invoiceDetails = [];
		this.totalBalance = 0;
		this.totalPts = 0;
		if(data.invoices.length>0){
			this.invoices = true;
		}
		this.arrivalDate = data.start_date;
		this.departureDate = data.end_date;
		this.roomNumber = data.room_number;
		this.confirmation_number = data.confirmation_no;
		for(var k=0;k<data.invoices.length;k++){
			for(var it=0;it<data.invoices[k].bill_items.length;it++){
				if(data.invoices[k].bill_items[it].currency_code && data.invoices[k].bill_items[it].currency_code !== ''){
					this.currency = data.invoices[k].bill_items[it].currency_code;
					break;
				}
			}
		}

		this.invoiceDetails = JSON.parse(JSON.stringify(data.invoices));
		var obj;
		for(var j=0;j<this.pointsConversion.length;j++){
			obj = this.pointsConversion[j];
			if(obj.loyalty_account == 1 && obj.policy_type == 'Award' && obj.currency_code == this.currency){
				this.conversion_rate = obj.conversion_rate;
			}
		}
		if(this.memberData.balances.length>0){
			this.pointsvalueInvoiceCurrency = parseFloat(((this.memberData.balances[this.pointsIndex].balance) * (this.conversion_rate)).toFixed(2));
		}

		this.positiveBills = 0; 

		for (let i = 0; i < this.invoiceDetails.length; i++) {
			this.invoiceDetails[i].inputAmount = 0;
			this.invoiceDetails[i].Points = 0;
			this.invoiceDetails[i]['selected'] = false;
			this.invoiceDetails[i].dueError = false;
			this.totalBalance = 0;
			this.totalPts = 0;
		}
		if(this.invoiceDetails.length>0){
			this.invoiceDetails[0]['selected'] = true;
			if(this.invoiceDetails[0].current_balance <= this.pointsvalueInvoiceCurrency){
				this.invoiceDetails[0].inputAmount = this.invoiceDetails[0].current_balance;
			}
			else{
				this.invoiceDetails[0].inputAmount = this.pointsvalueInvoiceCurrency;
			}
			this.BalanceUpdate(this.invoiceDetails[0],2);
			
		}
	}

	// selectAll() {
	// 	if(this.allSelected){
	// 		for(var x=0;x<this.invoiceDetails.length; x++){
	// 			this.invoiceDetails[x]['selected'] = false;
	// 		}
	// 		this.allSelected = false;
	// 		this.totalBalance = 0;
	// 		this.totalBal = 0;
	// 		this.totalPts = 0;
	// 		this.totalPoints = 0;
	// 		this.selectedBills = 0;
	// 	}
	// 	else{
	// 		this.allSelected = true;
	// 		this.totalBalance = 0;
	// 		this.totalPts = 0;
	// 		for (let i = 0; i < this.invoiceDetails.length; i++) {
	// 			if(this.invoiceDetails[i].amount<=0){
	// 				this.invoiceDetails[i]['selected'] = false;
	// 				this.invoiceDetails[i].Points = 0;
	// 			}
	// 			else{
	// 				this.invoiceDetails[i].Points = (this.invoiceDetails[i].amount/this.conversion_rate).toFixed(0);
	// 				this.invoiceDetails[i]['selected'] = true;
	// 				this.totalBalance = this.totalBalance + parseFloat(parseFloat(this.invoiceDetails[i].amount).toFixed(2));
	// 				this.totalPts = this.totalPts + parseInt(this.invoiceDetails[i].Points);
	// 			}
	// 		}
	// 		this.totalBal = this.totalBalance;
	// 		this.totalPoints = this.totalPts;
	// 		this.BalanceUpdate(1);
	// 		this.selectedBills = this.positiveBills;
	// 	}
	// }

	// selectDeselectInvoice(index) {
	// 	if(this.invoiceDetails[index].amount<=0){
	// 		return;
	// 	}
	// 	this.invoiceDetails[index].selected = !this.invoiceDetails[index].selected;
	// 	if (this.invoiceDetails[index].selected) {
	// 		this.selectedBills++;
	// 		this.totalBalance = this.totalBalance + parseFloat(parseFloat(this.invoiceDetails[index].amount).toFixed(2));
	// 		this.totalPts = this.totalPts + parseInt(this.invoiceDetails[index].Points);
	// 	} else {
	// 		this.selectedBills--;
	// 		this.totalBalance = this.totalBalance - parseFloat(this.invoiceDetails[index].amount);
	// 		this.totalPts = this.totalPts - parseInt(this.invoiceDetails[index].Points);
	// 	}
	// 	this.totalBal = this.totalBalance;
	// 	this.totalPoints = this.totalPts;
	// 	if(this.memberData.balances[0]){
	// 		if(this.totalPoints > this.memberData.balances[0].balance){
	// 			this.totalPoints = this.memberData.balances[0].balance;
	// 			this.BalanceUpdate(1);
	// 		}
	// 	}
	// 	if(this.selectedBills === this.positiveBills){
	// 		this.allSelected = true;
	// 	}
	// 	else{
	// 		this.allSelected = false;
	// 	}
	// }

	showPaymentConfirmation(paymentconfirmation, value) {
		switch (value) {
			case "confirmation": {
				this.redemption = false;
				this.consume = false;
				this.confirmation = true;
				var req = {
					"window_number": '',
					'amount': 0,
					"redemption_bit_id": '',
					"resort": this.resort,
					"resv_name_id": this.reservation_id
				};
				this.paymentData['data'] = [];
				for(var i=0;i<this.invoiceDetails.length;i++){
					var obj = JSON.parse(JSON.stringify(req));
					if(this.invoiceDetails[i].selected){
						obj['window_number'] = this.invoiceDetails[i].window_number;
						obj['amount'] = parseFloat(this.invoiceDetails[i].inputAmount);
						this.paymentData['data'].push(obj);
					}
				}
				break;
			}
			case "redemption": {
				this.redemption = true;
				this.consume = false;
				this.confirmation = false;
				this.totalPoints = this.offerData.productAwardPrice;
				break;
			}
			case "consume": {
				this.redemption = false;
				this.consume = true;
				this.confirmation = false;
				break;
			}
			default:
				break;
		}
		this.open(paymentconfirmation, 'modalpaymentconfirmation');
	}

	getAwardOffers() {
		this.loader = true;
		this.memberService.getAllSponsorLocations()
			.then((data: any) => {
				this.locations = data;
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
		this.memberService
			.getAwardOffers(this.memberData.member_id)
			.then((data: any) => {
				this.awardOffers = [];
				var tomorrow = new Date();
				tomorrow.setDate(new Date().getDate()+1);
				var nextDay = moment(tomorrow, 'DD/MM/YYYY h:mm A').format("YYYY-MM-DDTHH:mm") + "" + moment().tz('Asia/Dubai').format("Z");
				for(var i=0;i<data.data.length;i++){
					if((data.data[i].expiryDate > nextDay || !data.data[i].expiryDate) && data.data[i].productAwardPrice && data.data[i].productAwardPrice>0){
						this.awardOffers.push(data.data[i]);
					}
				}
				this.filterAwardOffers();
				this.loader = false;
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
				this.loader = false;
			});
	}

	filterAwardOffers() {
		this.filteredAwardOffers = [];
		var str = '';
		var substr = this.searchval.toLowerCase();
		var eligible_locations = [];
		for(var i=0;i<this.awardOffers.length;i++){
			str = this.awardOffers[i].offerName.toLowerCase();
			eligible_locations = [];
			if(this.awardOffers[i].offerExtraData && this.awardOffers[i].offerExtraData.eligible_sponsors){
				eligible_locations = this.awardOffers[i].offerExtraData.eligible_sponsors.replace(/ /g, "").split('|');
			}
			if(str.indexOf(substr) !== -1 && (this.sponsor_details.id===this.awardOffers[i].sponsorID || (eligible_locations.indexOf(this.resort) !== -1))){
				this.filteredAwardOffers.push(this.awardOffers[i]);
			}
		}
	}

	showOfferDetails(index, paymentconfirmation) {
		this.offerData = this.filteredAwardOffers[index];
		this.showPaymentConfirmation(paymentconfirmation, 'redemption');
	}

	open(content, modalclass) {
		this.modalRef = this.modalService.open(content, { windowClass: modalclass });
	}

	filterprivileges() {
		this.filteredPrivileges = [];
		var str = '';
		var substr = this.privsearchval.toLowerCase();
		var privCode = '';
		var eligible_locations = [];
		for(var i=0;i<this.privileges.length;i++){
			str = this.privileges[i].offerName.toLowerCase();
			eligible_locations = [];
			if(this.privileges[i].offerExtraData && this.privileges[i].offerExtraData.eligible_sponsors){
				eligible_locations = this.privileges[i].offerExtraData.eligible_sponsors.replace(/ /g, "").split('|');
			}
			privCode = this.privileges[i].uniquePrivilegeCode.toLowerCase();
			if((str.indexOf(substr) !== -1 || privCode.indexOf(substr)!== -1) && (this.privileges[i].sponsorID === this.sponsor_details.id || (eligible_locations.indexOf(this.resort) !== -1))){
				this.filteredPrivileges.push(this.privileges[i]);
			}
		}
	}

	getPrivileges() {
		this.loader = true;
		this.memberService
			.getPrivileges(this.memberData.member_id)
			.then((data: any) => {
				this.privileges = [];
				for (let i = 0; i < data.data.length; i++) {
					var validTillDate = new Date(data.data[i].validTill);
					if(validTillDate > this.todayData){
						this.privileges.push(data.data[i]);
						if(data.data[i].offerExtraData && data.data[i].offerExtraData.multiple_usage === 'Y'){
							for(var j=i+1;j<data.data.length;j++){
								if(data.data[j].offerExtraData && data.data[j].offerExtraData.multiple_usage === 'Y' && data.data[j].productName === data.data[i].productName && data.data[j].offerName === data.data[i].offerName){
									data.data.splice(j, 1);
									j--;
								}
							}
						}
					}	
				}
				this.loader = false;
				this.filterprivileges();
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
				this.loader = false;
			});
	}

	getBitData(cursor) {
		if (this.form.value && this.form.value.start) {
      		var x= this.form.value.start[0];
      		var start_date = x.getFullYear()+'-'+(x.getMonth()+1)+'-'+((x.getDate()<10)?"0"+x.getDate():x.getDate());
      		var y= this.form.value.start[1];
      		if(this.form.value.start[1]){
      			var end_date = y.getFullYear()+'-'+(y.getMonth()+1)+'-'+((y.getDate()<10)?"0"+x.getDate():y.getDate());
      		}
    	}
    	if(typeof cursor !== 'string'){
    		cursor = null;
    	}
		this.loader = true;
		this.memberService
			.getBitData(this.memberData.member_id, this.bitcatgeory, this.bittype, this.sponsor_details.id, start_date, end_date, cursor)
			.then((data: any) => {
				this.bitData = data.body;
				this.prevUrl = data.headers.get('prev');
				this.nextUrl = data.headers.get('next');
				this.setBITData();
				for(var i=0;i<this.bitData.length;i++){
					let index = _.findIndex(this.BitTypesList, {
						"code": this.bitData[i].h_bit_type
					});
					if(index !== -1){
						this.bitData[i].h_bit_type = this.BitTypesList[index].name;
					}
					let ind = _.findIndex(this.bitData[i].allActionsArray,{
						"subject_name": 'Tier Points'
					});
					if(ind !== -1){
						this.bitData[i].tierPoints = this.bitData[i].allActionsArray[ind].value_n;
					}
				}
				this.loader = false;
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
				this.loader = false;
			});
	}

	bindChange(){
		let subscription = timer(0, 100).pipe().subscribe(x=>{
			var elmnt = document.getElementsByClassName("ng2-flatpickr-input") as HTMLCollectionOf<HTMLElement>;
			elmnt[0].addEventListener('change', this.getBitData.bind(this));
			subscription.unsubscribe();
		})
		
	}

	exportBitData() {
		var downloadIconLoader = document.getElementById('bits-download-loader');
        if (downloadIconLoader) {
            downloadIconLoader.classList.add('download-loader');
        }
        if (this.form.value && this.form.value.start) {
      		var x= this.form.value.start[0];
      		var start_date = x.getFullYear()+'-'+(x.getMonth()+1)+'-'+((x.getDate()<10)?"0"+x.getDate():x.getDate());
      		var y= this.form.value.start[1];
      		if(this.form.value.start[1]){
      			var end_date = y.getFullYear()+'-'+(y.getMonth()+1)+'-'+((y.getDate()<10)?"0"+x.getDate():y.getDate());
      		}
    	}
		this.memberService
			.exportMemberBITData(this.memberData.member_id, this.bitcatgeory, this.bittype, this.sponsor_details.id, start_date, end_date)
            .then((data: any) => {
                this.exportDataResponse(data);
            })
            .catch(error => {
				if(error.status === 200){
					this.exportDataResponse(error.error.text);
				}
			});
	}

	exportDataResponse = function(data) {
		var encodedUri = encodeURIComponent(data);
		var link = document.createElement("a");
		link.setAttribute("href", 'data:attachment/csv;charset=utf-8,' + encodedUri);
		link.setAttribute("download", "my_data.csv");
		document.body.appendChild(link); // Required for FF

		link.click();
        var downloadIconLoader = document.getElementById('bits-download-loader');
        
        if (downloadIconLoader && downloadIconLoader.classList.contains('download-loader')) {
            downloadIconLoader.classList.remove('download-loader');
        }
    }

	setBITData(){
        for (var m = 0; m < this.bitData.length; m++) {
            this.bitData[m].orderFormat = this.bitData[m].h_bit_date;
            this.bitData[m].isRewardPrivilge = false;
            this.bitData[m].rewardArray = [];
            this.bitData[m].allActionsArray = [];
            this.bitData[m].balancesArray = [];
            this.bitData[m].rewardArraySum = 0;
			      this.bitData[m].tierPointsArraySum = 0;
            this.bitData[m].total_privilege_count = 0;
            this.bitData[m].loyalty_account_type = "";
            if (this.bitData[m].offer_actions.length > 0) {
                for (var n = 0; n < this.bitData[m].offer_actions.length; n++) {
                    this.bitData[m].offer_actions[n].rewardArrayCurrency = '';
                    this.bitData[m].rewardArrayCurrency_symbol = '';
                    this.bitData[m].privilege_name = "";
                    this.bitData[m].privilege_id = "";
                    if (this.bitData[m].offer_actions.length > 0) {
                        if (this.bitData[m].offer_actions[n].type === 'REWARD_POINTS' || this.bitData[m].offer_actions[n].type === 'REDEEM_POINTS') {
                            if (this.bitData[m].rewardArray.length !== 0) {
                                var index = _.findIndex(this.bitData[m].rewardArray, {
                                    "subject": this.bitData[m].offer_actions[n].subject
                                });
                                if (index > -1) {
                                    this.bitData[m].rewardArray.push({
                                        "value_n": this.bitData[m].offer_actions[n].value_n,
                                        "subject_name": this.bitData[m].offer_actions[n].subject_name,
                                        "subject": this.bitData[m].offer_actions[n].subject,
                                        "type": this.bitData[m].offer_actions[n].type,
                                        "rewardArrayCurrency": this.bitData[m].offer_actions[n].subject_name
                                    });
                                    this.bitData[m].rewardArrayCurrency = this.bitData[m].rewardArray[0].subject_name;
                                }
                                this.bitData[m].allActionsArray.push({
                                    "value_n": this.bitData[m].offer_actions[n].value_n,
                                    "subject_name": this.bitData[m].offer_actions[n].subject_name,
                                    "subject": this.bitData[m].offer_actions[n].subject,
                                    "type": this.bitData[m].offer_actions[n].type,
                                    "rewardArrayCurrency": this.bitData[m].offer_actions[n].subject_name
                                });
                            } else {
                                for (var l = 0; l < this.loayltyAccounts.length; l++) {
                                    if (this.bitData[m].offer_actions[n].subject_name === this.loayltyAccounts[l].loyalty_currency) {
                                        this.bitData[m].loyalty_account_type = this.loayltyAccounts[l].currency_type;
                                    }
                                }

                                if( this.bitData[m].loyalty_account_type === 'Redemption'){
                                    this.bitData[m].rewardArray.push({
                                        "value_n": this.bitData[m].offer_actions[n].value_n,
                                        "subject_name": this.bitData[m].offer_actions[n].subject_name,
                                        "subject": this.bitData[m].offer_actions[n].subject,
                                        "type": this.bitData[m].offer_actions[n].type,
                                        "rewardArrayCurrency": this.bitData[m].offer_actions[n].subject_name
                                    });
                                    this.bitData[m].allActionsArray.push({
                                        "value_n": this.bitData[m].offer_actions[n].value_n,
                                        "subject_name": this.bitData[m].offer_actions[n].subject_name,
                                        "subject": this.bitData[m].offer_actions[n].subject,
                                        "type": this.bitData[m].offer_actions[n].type,
                                        "rewardArrayCurrency": this.bitData[m].offer_actions[n].subject_name
                                    });
                                this.bitData[m].rewardArrayCurrency = this.bitData[m].rewardArray[0].subject_name;
                                } else {
                                    this.bitData[m].allActionsArray.push({
                                    "value_n": this.bitData[m].offer_actions[n].value_n,
                                    "subject_name": this.bitData[m].offer_actions[n].subject_name,
                                    "subject": this.bitData[m].offer_actions[n].subject,
                                    "type": this.bitData[m].offer_actions[n].type,
                                    "rewardArrayCurrency": this.bitData[m].offer_actions[n].subject_name
                                    });
                                }
                            }
                            this.bitData[m].rewardArraySum = this.reduce(this.bitData[m].rewardArray);
                        }
                        if (this.bitData[m].offer_actions[n].type === 'REWARD_PRIVILEGE') {
                            this.bitData[m].isRewardPrivilge = true;
                            this.bitData[m].privilegeName = this.bitData[m].offer_actions[n].subject_name;
                            //We are showing first offer that got applied for the bit.
                            this.bitData[m].offer_name = this.bitData[m].offer_actions[0].offer_name;
                            this.bitData[m].privilegeId = this.bitData[m].offer_actions[n].subject;
                            this.bitData[m]["total_privilege_count"] = this.bitData[m]["total_privilege_count"] + this.bitData[m].offer_actions[n].privilege_quantity;
                        }
                        if (this.bitData[m].offer_actions[n].type === 'TRANSFER_POINTS') {
                            if(this.bitData[m].rewardArraySum === 0){
                                this.bitData[m].rewardArraySum = this.bitData[m].offer_actions[n].value_n;
                                if(this.bitData[m].offer_actions[n].transfer_action === 'SEND'){
                                    this.bitData[m].rewardArraySum = -Math.abs(this.bitData[m].rewardArraySum);
                                }
                                for(var x=0;x<this.loayltyAccounts.length;x++){
                                    if(this.loayltyAccounts[x].id ==  this.bitData[m].offer_actions[n].subject){
                                        this.bitData[m].rewardArrayCurrency = this.loayltyAccounts[x].loyalty_currency;
                                    }
                                }
                            }
                        }
                        if (this.bitData[m].offer_actions[n].type === 'TRANSFER_PRIVILEGE') {
                            this.bitData[m].offer_name = this.bitData[m].offer_actions[0].offer_name;
                            this.bitData[m].privilegeId = this.bitData[m].offer_actions[n].subject;
                            this.bitData[m]["total_privilege_count"] = this.bitData[m]["total_privilege_count"] + this.bitData[m].offer_actions[n].privilege_quantity;
                        }
						if(this.bitData[m].offer_actions[n].subject_name === 'Tier Points'){
							if(this.bitData[m].offer_actions[n].reward_tags[0] === 'BNS' || this.bitData[m].offer_actions[n].reward_tags[0] === 'BASE'){
								this.bitData[m].tierPointsArraySum += this.bitData[m].offer_actions[n].value_n;
							}
						}
                        if(this.bitData[m].offer_actions[n].type === 'UPDATE_ATTRIBUTE' || this.bitData[m].offer_actions[n].type === 'REWARD_PRIVILEGE'){
                            var isredeem = _.findIndex(this.bitData[m].offer_actions, {
                                'type': "REDEEM_POINTS"
                            });

                            if(isredeem !== -1){
                                this.bitData[m].offer_name = this.bitData[m].offer_actions[isredeem].offer_name;
                            } else {
                                var isrewardpri = _.findIndex(this.bitData[m].offer_actions, {
                                    'type': "REWARD_PRIVILEGE"
                                });
                                if(isrewardpri !== -1){
                                    this.bitData[m].offer_name = this.bitData[m].offer_actions[isrewardpri].offer_name;
                                } else{
                                    this.bitData[m].offer_name = this.bitData[m].offer_actions[0].offer_name;
                                }
                            }
                        }
                        if (this.bitData[m].h_bit_category === 'ENROLLMENT' || this.bitData[m].h_bit_category === 'DEAL_REDEMPTION') {
                            this.bitData[m].offer_name = this.bitData[m].offer_actions[0].offer_name;
                        }
                    }
                    for (var j = 0; j < this.loayltyAccounts.length; j++) {
                        if (this.bitData[m].rewardArrayCurrency === this.loayltyAccounts[j].loyalty_currency) {
                            this.bitData[m].rewardArrayCurrency_symbol = this.loayltyAccounts[j].logo;
                        }
                    }
                }
                var maxvalue = Math.max.apply(Math, this.bitData[m].allActionsArray.map(function(offer) { return offer.value_n; }));
                var maxindex = _.findIndex(this.bitData[m].offer_actions, {
                    "value_n": maxvalue,
                    "type" : 'REWARD_POINTS'
                });
                if(maxindex> -1){
                    this.bitData[m].offer_name = this.bitData[m].offer_actions[maxindex].offer_name;
                }else{
                    if(this.bitData[m].offer_actions[0].type !==  'UPDATE_ATTRIBUTE' && this.bitData[m].offer_actions[0].type !== 'REWARD_PRIVILEGE'){
                        this.bitData[m].offer_name = this.bitData[m].offer_actions[0].offer_name;
                    }
                    for (var j = 0; j < this.loayltyAccounts.length; j++) {
                        if (this.bitData[m].rewardArrayCurrency === this.loayltyAccounts[j].loyalty_currency) {
                            this.bitData[m].rewardArrayCurrency_symbol = this.loayltyAccounts[j].logo;
                        }
                    }
                }

            } else {
                if (this.bitData[m].header.offer_id_text && this.bitData[m].header.offer_id_text !== '') {
                    this.bitData[m].offer_name = this.bitData[m].header.offer_id_text;
                }
                this.bitData[m].rewardArrayCurrency_symbol = '';
            }
            if(this.bitData[m].loyalty_balances!==null){
                for(var o = 0; o < this.bitData[m].loyalty_balances.length; o++){
                    var bIndex =  _.findIndex(this.loayltyAccounts, {
                        "id": parseInt(this.bitData[m].loyalty_balances[o].loyalty_account_id)
                    });
                    if (bIndex !== -1 && this.bitData[m].loyalty_balances[o].rewarded!==0) {
                        this.bitData[m]['balancesArray'].push({
                            "balance": this.bitData[m].loyalty_balances[o].rewarded,
                            "loyalty_account_symbol": this.loayltyAccounts[bIndex].logo,
                            "loyalty_account_id": this.bitData[m].loyalty_balances[o].loyalty_account_id,
                            "loyalty_account_name": this.loayltyAccounts[bIndex].loyalty_currency
                        });
                    }
                }
            }
            
        }
    };

    reduce = function(array) {
        var val = 0;
        for (var i = 0; i < array.length; i++) {
            if (array[i].type === "REWARD_POINTS") {
                val = val + array[i].value_n;
            } else {
                val = val - array[i].value_n;
            }
        }
        return val;
    };

    getLoyaltyAccounts() {
    	this.memberService.getLoayltyAccounts()
			.then((data: any) => {
				this.loayltyAccounts = data;
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
    }

	showPrivilegeDetails(index, paymentconfirmation) {
		this.offerData = this.filteredPrivileges[index];
		this.showPaymentConfirmation(paymentconfirmation, 'consume');
	}

	getIntegerValue(value: number): number {
		return Math.floor(value);
	}
}
