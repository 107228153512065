import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-consumereward',
  templateUrl: './consumereward.component.html',
  styleUrls: ['./consumereward.component.less']
})
export class ConsumerewardComponent implements OnInit {
  rewardconsumed : boolean = false;
  constructor(
		private modalService: NgbModal
	) { }

  ngOnInit() {
  }
  close() {
		this.modalService.dismissAll();
	}

}
