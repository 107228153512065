import { DatePipe } from '@angular/common';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { CommonService, MemberService } from 'src/services';
import { MemberEnrollService } from 'src/services/memberenroll.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less']
})
export class ReportsComponent implements OnInit {

  reportsDetails: boolean = false;
  tab: string = "summaryView";
  summayViewDetail: boolean;
  enrolmentViewDetail: boolean;
  viewColleagueDetails: boolean;
  strColleagueID: string = '';
  colleagueData = [];
  enrolmentData: any;
  filteredSponsorData:any;
  resort: string = "";
  sponsor_details: any = {
    name: "",
    id: null,
    address: ''
  };
  selectDate: any = new Date();
  private options: any[];
  filteredColleagueData: any = [];
  requiredColleagueData: any = [];
  searchval = '';

  profileId: string = "";
  pmsAgentName: string = '';
  reservation_id: string = '';

  dimension = 'month';
  // start_date = '20210101';
  // end_date = '20211231';
  start_date = '';
  end_date = '';

  dimension_1 = "colleague_id";
  dimension_2 = "colleague_id";
  memberData: any;

  sponsor_id = '';
  displayTotal: boolean;
  viewMoreDetails: boolean;

  memberDetails: any;
  membershipStage = ['Active','Expired','Pending Opt In','Inactive','Cancelled'];
  membershipStages = "Active,Expired,Pending Opt In,Inactive,Cancelled";
  nextURL:any;
  totalDataColleagueWise = 12;

  date_of_Joining_after: any;
  date_of_Joining_before: any;
  tempcursor = '';
  months = new Array(12);
  sponsorArray = [];
  totalEnrollment = {};
  selectedSponsor = '';

  constructor(
    private commonService: CommonService,
    private memberEnrollService: MemberEnrollService,
    private datePipe: DatePipe,
    private memberService: MemberService,
  ) {
  }

  ngOnInit() {
    this.profileId = this.commonService.getParam('g').toString();
    this.pmsAgentName = this.commonService.getParam('u').toString().replace("%20", " ");
    this.selectDate = this.datePipe.transform(this.selectDate, 'yyyy');
    this.options = [this.selectDate,Number(this.selectDate) - 1];
    this.summayViewDetail = true;
    this.enrolmentViewDetail = false;
    this.displayTotal = true;
    this.viewMoreDetails = true;
    this.strColleagueID = '';
    this.resort = this.commonService.getParam('p').toString();
    localStorage.setItem('resort', this.resort);
    this.sponsor_id = localStorage.getItem('sponsorID');
    this.sponsorDetails();
    this.filterColleagueData();
    this.summaryEnrolmentDetails(this.selectDate);
    this.getMemberDetails(this.strColleagueID,this.totalDataColleagueWise);
    this.getEnrolledColleagueDetails();
    this.requiredColleagueData = this.colleagueData;
  }

  myFunction() {
    if (window.outerWidth < 1440) {
      let x;
      x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = (window.outerWidth / 14.40).toString() + '%';
    }
    else {
      let x;
      x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = '100%';
    }
  }

  getSummaryDetail() {
    this.summayViewDetail = true;
    this.enrolmentViewDetail = false;
    this.viewColleagueDetails = false;
  }

  getEnrolmentDetail() {
    this.totalEnrolmentDetails(this.selectDate);
    this.summayViewDetail = false;
    this.enrolmentViewDetail = true;

  }
  totalEnrolmentDetails(dateValue) {
    this.memberEnrollService
      .getSummaryViewEnrolmentData(this.start_date, this.end_date, this.dimension,this.sponsor_id,this.membershipStages,this.dimension_2,null,this.selectedSponsor)
      .then((res: any) => {
        for (let j = 0; j < res.enrollments_by_month.length; j++) {
          if (res.enrollments_by_month[j][1] != j + 1) {
            let arr = [Number(dateValue), j + 1, 0];
            res.enrollments_by_month.splice(j, 0, arr);
          }
        }
        var len = res.enrollments_by_month.length;
        while (len < 12) {
          res.enrollments_by_month.push([Number(dateValue), ++len, 0]);
        }
        if(res.total_enrollments.length == 0){
           res.total_enrollments.push([Number(dateValue), 0])
        }
        this.enrolmentData = res;
        console.log(this.enrolmentData);
      });

  }
  getColleagueDetails(index) {
    this.viewColleagueDetails = true;
    this.tempcursor = "";
    this.strColleagueID = this.colleagueData[index].id;
    this.getMemberDetails(this.strColleagueID,this.totalDataColleagueWise);
  }

  sponsorDetails() {
    this.memberEnrollService
      .getSponsorDetailsByShortName(this.resort)
      .then((data: any) => {
        for (var j = 0; j < data.length; j++) {
          if (data[j].short_name === this.resort) {
            this.sponsor_details.name = data[j].name;
            this.sponsor_details.address = data[j].address;
            this.sponsor_details.id = data[j].id;
          }
        }
      });
  }

  summaryEnrolmentDetails(dateValue) {
    this.sponsorArray = [];
    this.totalEnrollment = [];
    this.enrolmentData = [];
    let enrollmentcode;
    if (this.resort === 'MJ'){
      enrollmentcode = 'enrollment_code'
      this.memberEnrollService.getSummaryViewEnrolmentData(this.start_date, this.end_date, 
        this.dimension, this.sponsor_id, this.membershipStages, this.dimension_2, enrollmentcode,this.selectedSponsor)
        .then((res: any) => {
          let filteredArray = {};
          res.enrollments_by_month.forEach(data => { 
            if(! (data[2] in filteredArray)){
              filteredArray[data[2]] = [];
              if(!this.sponsorArray.includes(data[2])){
                this.sponsorArray.push(data[2]);
              }
            };
            filteredArray[data[2]].push(data);
          });
          res.total_enrollments.forEach(data => {
            if(! (data[1] in this.totalEnrollment)){
              this.totalEnrollment[data[1]] = data[2];
            };
          });
          this.sponsorArray.forEach(val => {
            for (let i = 1; i <= 12; i++){
              let index1 = true;
              for (let j = 0; j < filteredArray[val].length; j++){
                if (filteredArray[val][j][1] === i){
                  index1 = false;
                }
              }
              if (index1) {
                filteredArray[val].splice(i - 1, 0, [0, i , 0, 0]);
              }
            }
          });
          this.filteredSponsorData = filteredArray;
        });
    }else{
      this.totalEnrolmentDetails(this.selectDate);
    }
    

  }

  filterColleagueData() {
    this.filteredColleagueData = [];
    var str = '';
    var substr = this.searchval.toLowerCase();

    for (var i = 0; i < this.colleagueData.length; i++) {
      str = this.colleagueData[i].id.toLowerCase();
      if (str === substr) {
        this.filteredColleagueData.push(this.colleagueData[i]);
      }
    }
    this.displayTotal = false;
    this.colleagueData = this.filteredColleagueData;
    if (this.searchval === '') {
      this.displayTotal = true;
      this.selectedDateResult(this.selectDate);
      this.colleagueData = this.requiredColleagueData;
    }
  }

  closeMemberPage() {
    this.viewColleagueDetails = false;
    this.memberData = null;
  }

  rediectToProfilePage() {
    var str = '?g=' + this.profileId + '&p=' + this.resort + '&u=' + this.pmsAgentName;
    if (this.reservation_id.length > 1) {
      str += '&resv_name_id=' + this.reservation_id;
    }
    var url = window.location.href;
    var domain = url.split('reports')[0];
    window.location.href = domain + str;
  }

  selectedDateResult(value) {
    this.start_date = value.toString() + '0101';
    this.end_date = value.toString() + '1231';
    this.date_of_Joining_after = value.toString() + '-' + '01-01';
    this.date_of_Joining_before = value.toString() + '-' + '12-31';
    this.viewColleagueDetails = false;
    if (this.summayViewDetail == true) {
      this.summaryEnrolmentDetails(value);
      this.colleagueData = [];
      this.displayTotal = true;
      this.getEnrolledColleagueDetails();
    }
    if (this.enrolmentViewDetail == true) {
      this.colleagueData = [];
      this.displayTotal = true;
      this.getEnrolledColleagueDetails();
      this.requiredColleagueData = this.colleagueData;
      this.summaryEnrolmentDetails(value);
    }
  }


  getMemberDetails(clgid,totalData) {
    this.viewMoreDetails = true;
    this.memberEnrollService
      .getMemberData(this.date_of_Joining_after,this.date_of_Joining_before,clgid,totalData,this.sponsor_id,this.membershipStage)
      .then((res: any) => {

        for(var i=0;i<res.body.length;i++){
          res.body[i].date_of_joining = this.datePipe.transform(res.body[i].date_of_joining, 'dd/MM/yyyy');
        }
        this.memberData = res.body;
        this.nextURL = res.headers.get('next');
        if(this.memberData.length<12){
          this.viewMoreDetails = false;
        }
      });
  }

  getEnrolledColleagueDetails() {
    this.memberEnrollService
      .getEnrolledColleagueData(this.start_date, this.end_date, this.dimension, this.dimension_1,this.sponsor_id,this.membershipStages,this.selectedSponsor)
      .then((res: any) => {
        for(let i=0;i<res.enrollments_by_month.length;i++){
          if(this.colleagueData.length!=0 && this.colleagueData.find(id=>id.id=== res.enrollments_by_month[i][2])){
            this.colleagueData.forEach(element => {
              if(element.id === res.enrollments_by_month[i][2]){
                element.months.push({id:res.enrollments_by_month[i][1],
                  value:res.enrollments_by_month[i][3]})
              }
            });
          }else{
            this.colleagueData.push(
              {
                months:[{id:res.enrollments_by_month[i][1],
                  value:res.enrollments_by_month[i][3]}],
                  id:res.enrollments_by_month[i][2]
              }
            )
          }
        }
 
        this.colleagueData.forEach(data => {
          let months = [{id: 1, value: 0,},{id: 2, value: 0},{id: 3, value: 0},{id: 4, value: 0},{id: 5, value: 0},{id: 6, value: 0},{id: 7, value: 0},{id: 8, value: 0},{id: 9, value: 0},{id: 10, value: 0},{id: 11, value: 0},{id: 12, value: 0}];
          months.forEach(element => {
            data.months.forEach(ele => {
              if(ele.id == element.id) {
                element.value = ele.value;
              }
            });
          });
          data.months = months;
        });

        for(let i=0;i<res.total_enrollments.length;i++){
          if(this.colleagueData.length!=0 && this.colleagueData.find(id=>id.id=== res.total_enrollments[i][1])){
            this.colleagueData.forEach(element => {
              if(element.id === res.total_enrollments[i][1]){
                element.months.push({id:res.total_enrollments[i][1],
                  value:res.total_enrollments[i][2]})
              }
            });
          }
        }

        if(this.colleagueData.length == 0){
          this.displayTotal = false;
        }
        
          
        
        // console.log(this.colleagueData);
        // this.colleagueData.forEach(data => {
        //   let count = 1;
        //   var monthObject;
        //   // for(var i=1;i<13;i++){
        //     data.months.forEach(element => {
        //       if(Number(element.id) != count) {
        //         monthObject = {id: count,value: 0};
        //         // console.log(monthObject);
        //         // console.log(data.months);
        //         data.months.splice(count-1,0,monthObject);              
        //         // console.log(this.colleagueData);
        //         // console.log(count);               
        //       }
        //       count++;
        //     });
            
        // });

        // this.colleagueData.forEach(data => {
        //   if(data.months.length<11){
        //   for(var i=data.months.length+1;i<=12;i++){
        //     data.months.push({id: i,value: 0});
        //   }
        // }
        // });

      });
  }

  loadNextMemberPage(clgid, totalData, cursor) {

    if(cursor==='None'){
      this.viewMoreDetails = false;
    }
    else if (cursor !== undefined && cursor!==null && this.nextURL !== null) {
      cursor = cursor.split("cursor=")[1].split("&")[0];
      if (this.tempcursor !== cursor) {
        this.tempcursor = cursor;
        this.memberEnrollService
          .loadNextMemberData(this.date_of_Joining_after, this.date_of_Joining_before, clgid, totalData, this.sponsor_id, this.membershipStage, cursor)
          .then((res: any) => {
            this.nextURL = res.headers.get('next');
            for (var i = 0; i < res.body.length; i++) {
              res.body[i].date_of_joining = this.datePipe.transform(res.body[i].date_of_joining, 'dd/MM/yyyy');
              this.memberData.push(res.body[i]);
            }
          });
      }
    }
  }
  selectedSponsorEnrollmentViewDetails(value){
    this.selectedSponsor = value;
   
    this.colleagueData = [];
      this.displayTotal = true;
      this.getEnrolledColleagueDetails();
      this.requiredColleagueData = this.colleagueData;
      this.getEnrolmentDetail();
  }

}
